var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border shadow-sm rounded-lg p-3",attrs:{"id":"exame-edition"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center",attrs:{"id":"exame-edition-collapse"},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('p',{staticClass:"d-inline font-weight-bold m-0 ml-3"},[_vm._v("\n      "+_vm._s(_vm.$t(
          'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.title'
        ))+"\n    ")]),_vm._v(" "),_c('hs-icon',{staticClass:"ml-3",attrs:{"variant":"light","icon":_vm.visible.visible ? 'chevron-up' : 'chevron-down',"size":16}})],1),_vm._v(" "),_c('b-collapse',{staticClass:"p-3",model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',[_c('hs-group',{attrs:{"label-for":"title","label-class":"font-weight-bold","label":_vm.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.title'
          )}},[_c('MInput',{attrs:{"id":"title","placeholder":_vm.$t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.placeholder'
            )},model:{value:(_vm.activity.title),callback:function ($$v) {_vm.$set(_vm.activity, "title", $$v)},expression:"activity.title"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('hs-group',{attrs:{"label-for":"max_attempts","label-class":"font-weight-bold","label":_vm.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.max_attempts'
          )}},[_c('MInput',{attrs:{"id":"max_attempts","placeholder":"1","min":"1","type":"number"},model:{value:(_vm.activity.max_attempts),callback:function ($$v) {_vm.$set(_vm.activity, "max_attempts", _vm._n($$v))},expression:"activity.max_attempts"}})],1),_vm._v(" "),_c('hs-group',{staticClass:"ml-5"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.min_grade'
            ))+"\n        ")]),_vm._v(" "),_c('MInput',{attrs:{"id":"min_grade","placeholder":"0","state":_vm.$v.activity.exam_lesson.min_grade.$error ? false : null},on:{"blur":function($event){return _vm.$v.activity.exam_lesson.min_grade.$touch()}},model:{value:(_vm.activity.exam_lesson.min_grade),callback:function ($$v) {_vm.$set(_vm.activity.exam_lesson, "min_grade", _vm._n($$v))},expression:"activity.exam_lesson.min_grade"}}),_vm._v(" "),_c('hs-form-invalid-feedback',{attrs:{"state":!_vm.$v.activity.exam_lesson.min_grade.$error}},[_c('span',{staticClass:"font-size-xs"},[_vm._v("\n            "+_vm._s(_vm.$t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.min_grade.invalid'
              ))+"\n          ")])])],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column justify-content-start gap-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(
          'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.title'
        )))]),_vm._v(" "),_c('div',{staticClass:"mt-1 d-flex flex-column justify-content-start tlw-gap-1"},[_c('RadioInputBox',{attrs:{"hide-box":true,"value":"always"},model:{value:(_vm.activity.show_comments),callback:function ($$v) {_vm.$set(_vm.activity, "show_comments", $$v)},expression:"activity.show_comments"}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.always'
            ))+"\n        ")]),_vm._v(" "),_c('RadioInputBox',{attrs:{"hide-box":true,"value":"approved"},model:{value:(_vm.activity.show_comments),callback:function ($$v) {_vm.$set(_vm.activity, "show_comments", $$v)},expression:"activity.show_comments"}},[_vm._v("\n          "+_vm._s(_vm.$t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.approved'
            ))+"\n        ")]),_vm._v(" "),_c('MXInputCheckbox',{attrs:{"value":true,"label":_vm.$t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.unlock_certificate'
            )},model:{value:(_vm.activity.exam_lesson.unlock_certificate),callback:function ($$v) {_vm.$set(_vm.activity.exam_lesson, "unlock_certificate", $$v)},expression:"activity.exam_lesson.unlock_certificate"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }