<template>
  <div id="exame-edition" class="border shadow-sm rounded-lg p-3">
    <div
      id="exame-edition-collapse"
      class="d-flex justify-content-between align-items-center"
      @click="visible = !visible"
    >
      <p class="d-inline font-weight-bold m-0 ml-3">
        {{
          $t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.title'
          )
        }}
      </p>
      <hs-icon variant="light" :icon="visible.visible ? 'chevron-up' : 'chevron-down'" class="ml-3" :size="16" />
    </div>
    <b-collapse class="p-3" v-model="visible">
      <div>
        <hs-group
          label-for="title"
          label-class="font-weight-bold"
          :label="
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.title'
            )
          "
        >
          <MInput
            id="title"
            v-model="activity.title"
            :placeholder="
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.placeholder'
              )
            "
          />
        </hs-group>
      </div>
      <div class="d-flex">
        <hs-group
          label-for="max_attempts"
          label-class="font-weight-bold"
          :label="
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.max_attempts'
            )
          "
        >
          <MInput id="max_attempts" v-model.number="activity.max_attempts" placeholder="1" min="1" type="number" />
        </hs-group>
        <hs-group class="ml-5">
          <label class="font-weight-bold">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.min_grade'
              )
            }}
          </label>

          <MInput
            id="min_grade"
            v-model.number="activity.exam_lesson.min_grade"
            placeholder="0"
            :state="$v.activity.exam_lesson.min_grade.$error ? false : null"
            @blur="$v.activity.exam_lesson.min_grade.$touch()"
          />
          <hs-form-invalid-feedback :state="!$v.activity.exam_lesson.min_grade.$error">
            <span class="font-size-xs">
              {{
                $t(
                  'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.min_grade.invalid'
                )
              }}
            </span>
          </hs-form-invalid-feedback>
        </hs-group>
      </div>
      <div class="d-flex flex-column justify-content-start gap-2">
        <span class="font-weight-bold">{{
          $t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.title'
          )
        }}</span>

        <div class="mt-1 d-flex flex-column justify-content-start tlw-gap-1">
          <RadioInputBox :hide-box="true" v-model="activity.show_comments" value="always">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.always'
              )
            }}
          </RadioInputBox>
          <RadioInputBox :hide-box="true" v-model="activity.show_comments" value="approved">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.comments.show_comments.approved'
              )
            }}
          </RadioInputBox>

          <MXInputCheckbox
            v-model="activity.exam_lesson.unlock_certificate"
            :value="true"
            :label="
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.main-content.components.activity-editor.lesson.unlock_certificate'
              )
            "
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import MInput from '@/components/MInput.vue';
import RadioInputBox from '@/shared/components/RadioInputBox.vue';
import MXInputCheckbox from '@/shared/components/MXInputCheckbox.vue';

export default {
  name: 'ActivityMonitor',
  components: {
    MInput,
    RadioInputBox,
    MXInputCheckbox,
  },
  data() {
    return {
      visible: true,
    };
  },
  props: {
    activity: {
      type: Object,
    },
  },
  validations() {
    return {
      activity: {
        exam_lesson: {
          min_grade: {
            minValue: v => v >= 0,
            maxValue: v => v <= 100,
          },
        },
      },
    };
  },
};
</script>
